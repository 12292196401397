/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

.mat-form-field-wrapper {
  padding-bottom: 0em !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 0.3em;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0em solid transparent;
}

.mat-form-field-flex {
  align-items: center !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  display: flex;
  align-content: center;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  display: flex;
  align-content: center;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon-button,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button {
  display: flex;
  align-content: center;
  justify-content: center;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0em !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0em !important;
}
